import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import debounce from "lodash/debounce";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getCompany,
  getContractCategory,
  getDepartments,
  getIssues,
  getPriorities,
  getProjects,
  getQueues,
  getRoles,
  getSkills,
  getSlas,
  getSubIssues,
  getTaskCategory,
  getTicketCategory,
  getTicketType,
  getlineOfBusiness,
} from "../../../../_metronic/requests/FilterRequest";
import { getGeneralSetting, getLocationDetails } from "../../../../_metronic/requests/GeneralSettingRequest";
import {
  getContracts,
  getLayoutandFiltersList,
  getLayoutandfilters,
  getOpportunities,
  getPhase,
  getProjectStatus,
  getProjectType,
  getResources,
  getResourcesWorkType,
  getSidebarList,
  getToPlanServiceCallStatus,
  getToPlanTaskStatus,
  selectLayoutAndMakeActive,
} from "../../../../_metronic/requests/PlanningRequest";
import { getResourceSetting } from "../../../../_metronic/requests/ResourceSettingRequest";
import { Loading } from "../../../components/loading/Loading";
import ViewSettings from "../../../components/modals/ViewSettings/viewSettings";
import { webSocketContext } from "../../../context/WebSocketContext";
import { areEqual } from "../../../helpers/global";
import { useAppSelector } from "../../../hook";
import { useAuth } from "../../auth/core/AuthCognito";
import ProjectView from "./ProjectView";
import { commonVariables, getFullYear, setProjectView } from "./util/common";
import { getTooltipData } from "../../superadmin/hint-tour/core/_request";
import {  Steps } from "intro.js-react";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../dashboard-management/core/_request";
import { loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "../../../helpers/latest_selection";
const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};
const { payloadDefault } = commonVariables();
const ProjectPage = () => {
  const { currentUser } = useAuth();
  const eventCalendarRef = useRef(null);
  const [payload, setPayload] = useState<any>({ ...payloadDefault });
  let [sidebarlistQuery, setSidebarlistQuery] = useState(false);
  const ids = useAppSelector((state) => state.counter);
  const [isDeletedLayouts, setIsDeletedLayOuts] = useState(false);
  const [isUpdateLayoutsName, setIsUpdateLayoutsName] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState<any>(getFullYear(""));
  let [viewBtnbackground, setviewBtnbackground] = useState("");
  let [viewclassName, setViewclassName] = useState("");
  let [Viewbtncolor, setViewbtncolor] = useState("");
  const [isviewShown, setViewIsShown] = useState(false);
  const [isFilterEnable, setFilterEnable] = useState(true);
  const [currentDate, setCurrentDate] = useState<any>(new Date());
  const [isSaveView, setIsSaveView] = useState<boolean>(false);
  const [isLoaded, setisLoaded] = useState<boolean>(false);

  const viewSettingsData = useSelector(
    (state: any) => state?.viewSettings?.ProjectViewData
  );

  const { isFetching: isfetchresourcesetting, data: resource_settings } =
    useQuery(
      "resources-Settinglist",
      async () => {
        return await getResourceSetting("");
      },
      { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    );

  const { data: worktype } = useQuery(
    "resources-worktype",
    () => {
      return getResourcesWorkType("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: islineOfBusinessFetch, data: lineOfBusiness } = useQuery(
    "lineOfBusiness",
    () => {
      // debugger
      return getlineOfBusiness();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isfetchgeneralsetting, data: generalSetting } = useQuery(
    `planning-resource-setting`,
    async () => {
      return await getGeneralSetting("");
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    isFetching: isFetchingAvailability,
    refetch: refetchAvailability,
    data: responseAvailability,
  } = useQuery(
    "company-project-List",
    async () => {
      payload.project_ids =  loadFromLocalStorage("expanded_projects")
      payload.phase_ids =  loadFromLocalStorage("expanded_phases")
      return await getSidebarList(payload);
    },
    {
      enabled: sidebarlistQuery,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setisLoaded(true)
        setSidebarlistQuery(false);
      },
    }
  );

  const { isFetching: isPeopleFetch, data: peoples } = useQuery(
    "project-people",
    () => {
      // debugger
      return getResources("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isCompanyFetch, data: companies } = useQuery(
    "project-company",
    () => {
      // debugger
      return getCompany();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isProjectFetch,
    refetch: refetch_projects,
    data: projects,
  } = useQuery(
    "project-screen-list",
    () => {
      return getProjects();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isfetchprojectstatus, data: project_status } = useQuery(
    "project-status",
    async () => {
      return await getProjectStatus();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isfetchlayoutandfilters, data: layoutandfilters } =
    useQuery(
      `project-layout-filters`,
      async () => {
        return await getLayoutandfilters({layoutpage:"project"});
      },
      {
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    );

  const { data: todo_status_serviceCall } = useQuery(
    "todo-status-serviceCall",
    async () => {
      return await getToPlanServiceCallStatus();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isfetchstatus, data: task_status } = useQuery(
    "project-todo-status",
    async () => {
      return await getToPlanTaskStatus();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: priorities, isFetching:isPrioritiesFetch } = useQuery(
    "project-Priorities",
    () => {
      // debugger
      return getPriorities();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: issues } = useQuery(
    "project-Issues",
    () => {
      // debugger
      return getIssues();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: subIssues } = useQuery(
    "project-sub-issues",
    () => {
      // debugger
      return getSubIssues();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { refetch: refetchLayouts, data: layoutAndFiltersData } = useQuery(
    "layoutAndFilters", // Unique key for this query
    async () => {
      return await getLayoutandFiltersList("project");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { socketMessage }: any = useContext(webSocketContext);
  const websocketCallback = useCallback(
    (event: any) => {
      let eData = JSON.parse(event.data);
      if (eData.organizationId == currentUser?.data?.user?.organizationId) {
        if (eData.type == "create_phase_success") {
          refetchphase();
        }
        if (eData.type == "create_project_success") {
          refetch_projects();
        }
      }
    },
    [socketMessage]
  );
  useEffect(() => {
    if (socketMessage) {
      websocketCallback(socketMessage);
    }
  }, [socketMessage]);
  
  useEffect(() => {
    refetchLayouts();
    setIsUpdateLayoutsName(false);
  }, [isUpdateLayoutsName]);

  const showviewcomponent = () => {
    viewBtnbackground === ""
      ? setviewBtnbackground("#164387")
      : setviewBtnbackground("");
    viewclassName === "viewShow"
      ? setViewclassName("")
      : setViewclassName("viewShow");
    Viewbtncolor === "" ? setViewbtncolor("text-white") : setViewbtncolor("");
    setViewIsShown((current) => !current);
  };

  const selectActiveLayout = async (layoutId: any,layoutType = "project") => {
    let response = await selectLayoutAndMakeActive(layoutId, layoutType);
    if (response.status == 200) {
      localStorage.setItem("setviewSetting",layoutType);
      localStorage.setItem("viewSettingIsShow", "0");
      removeFromLocalStorage("project_layout")
      removeFromLocalStorage("expanded_company")
      removeFromLocalStorage("expanded_projects")
      removeFromLocalStorage("expanded_phases")
      window.location.reload();
    }
  };

  const handleHeaderView = (event: any) => {
    let eventData = setProjectView(event);
    setCurrentYear(eventData.start_date?.slice(0, 4));
    let latestpayload = JSON.parse(JSON.stringify(payload));
    latestpayload.end_date = eventData.end_date;
    latestpayload.start_date = eventData.start_date;
    latestpayload.view_type = eventData.view_type;
    setCurrentDate(latestpayload.start_date);
    if (!isfetchlayoutandfilters && isFilterEnable) {
      setPayload(latestpayload);
    }
  };

  const [ height] = useWindowSize();

  const debouncedRefresh = useRef(
    debounce(() => {
      eventCalendarRef.current?.refresh();
    }, 6000)
  ).current; // Access the debounced function directly from the ref

  useEffect(() => {
    debouncedRefresh(); // Call the debounced function whenever height changes
  }, [height, debouncedRefresh]);

  useEffect(() => {
    //get layout list data
    refetchLayouts();
    setIsDeletedLayOuts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletedLayouts]);

  useEffect(() => {
    let changefilter = false;
    let latestpayload = JSON.parse(JSON.stringify(payload));
    if (
      (payload.project_leads &&
      !areEqual(payload.project_leads, ids.project_lead)) && ids.callApiforprojectfilter
    ) {
      latestpayload.project_leads = ids.project_lead;
      changefilter = true;
    }
    if (
      (payload.companies &&
      !areEqual(payload.companies, ids.project_company)) && ids.callApiforprojectfilter
    ) {
      latestpayload.companies = ids.project_company;
      changefilter = true;
    }
    if ((payload.projects && !areEqual(payload.projects, ids.projects)) && ids.callApiforprojectfilter) {
      latestpayload.projects = ids.projects;
      changefilter = true;
    }
    if (
      (payload.task_status &&
      !areEqual(payload.task_status, ids.task_status)) && ids.callApiforprojectfilter
    ) {
      latestpayload.task_status = ids.task_status;
      changefilter = true;
    }
    if (
      (payload.project_status &&
      !areEqual(payload?.project_status || [], ids?.project_status || [])) && ids.callApiforprojectfilter
    ) {
      latestpayload.project_status = ids.project_status;
      changefilter = true;
    }
    if (
      (payload?.lineOfBusiness &&
      !areEqual(payload?.lineOfBusiness || [], ids?.lineOfBusiness_ids || [])) && ids.callApiforprojectfilter
    ) {
      latestpayload.lineOfBusiness = ids.lineOfBusiness_ids;
      changefilter = true;
    }
    if (
      !areEqual(payload?.project_type || [], ids?.projecttype_ids || []) && ids.callApiforprojectfilter
    ) {
      latestpayload.project_type = ids.projecttype_ids;
      changefilter = true;
    }
    if (
      !areEqual(payload?.resources || [], ids?.resources_ids || []) && ids.callApiforprojectfilter
    ) {
      latestpayload.resources = ids.resources_ids;
      changefilter = true;
    }
    latestpayload.project_end_date = payload.project_end_date ?? "";
    latestpayload.project_start_date = payload.project_start_date ?? "";

    if ((parseInt(payload?.project_end_date_month_value) !== ids.project_end_date_month_value || payload?.project_end_date_option !== ids.project_end_date_option) && ids.callApiforprojectfilter) {
      latestpayload.project_end_date_month_value = ids.project_end_date_month_value;
      latestpayload.project_end_date_option = ids.project_end_date_option;
      let date = moment();
      if (ids.project_end_date_option === "isOnward") {
        date = date.add(ids.project_end_date_month_value, 'months');
      } else if (ids.project_end_date_option === "isBackward") {
        date = date.subtract(ids.project_end_date_month_value, 'months');
      }
      latestpayload.project_end_date = (ids.project_end_date_month_value > 0) ? date.format("YYYY-MM-DD") : "";
      changefilter = true;
    }

    if ((parseInt(payload?.project_start_date_month_value) !== ids.project_start_date_month_value || payload?.project_start_date_option !== ids.project_start_date_option) && ids.callApiforprojectfilter) {
      latestpayload.project_start_date_month_value = ids.project_start_date_month_value;
      latestpayload.project_start_date_option = ids.project_start_date_option;
      let date = moment();
      if (ids.project_start_date_option === "isOnward") {
        date = date.add(ids.project_start_date_month_value, 'months');
      } else if (ids.project_start_date_option === "isBackward") {
        date = date.subtract(ids.project_start_date_month_value, 'months');
      }
      latestpayload.project_start_date = (ids.project_start_date_month_value) ? date.format("YYYY-MM-DD") : "";
      changefilter = true;
    }
    if ((ids.project_view !== payload?.project_view) && ids.callApiforprojectfilter) {
      latestpayload.project_view = ids.project_view;
      changefilter = true;
    }
    if (changefilter && isFilterEnable) {
      setPayload(latestpayload);
    }
  }, [
    ids.project_lead,
    ids.project_company,
    ids.projects,
    ids.task_status,
    ids.project_status,
    ids.lineOfBusiness_ids,
    ids.projecttype_ids,
    ids.project_end_date_month_value,
    ids.project_end_date_month_value === 0,
    ids.project_end_date_option,
    ids.project_start_date_month_value === 0,
    ids.project_start_date_month_value,
    ids.project_start_date_option,
    ids.project_view,
    ids.resources_ids
  ]);

  useEffect(() => {
    if (
      !isfetchgeneralsetting &&
      !isfetchlayoutandfilters && !isfetchprojectstatus
    ) {
      let savedPreferences = loadFromLocalStorage('project_layout');
      let savedPreferencesproject = savedPreferences?.project_filter || layoutandfilters?.project_filter;
      if (Object.keys(savedPreferencesproject || "")?.length === 0) {
        let todayDate = moment();
        let payloadL = JSON.parse(JSON.stringify(payload));
        let weekStart: any;
        let weekEnd: any;
        let start: any;
        let end: any;
        if (
          generalSetting?.planning_screen_view !== "" &&
          payloadL?.view_type === ""
        ) {
          weekStart = todayDate
            .clone()
            .startOf(generalSetting?.planning_screen_view);
          weekEnd = todayDate
            .clone()
            .endOf(generalSetting?.planning_screen_view);
          start = weekStart.format("YYYY-MM-DD");
          end = weekEnd.format("YYYY-MM-DD");
          if (generalSetting?.planning_screen_view === "week") {
            weekStart = todayDate
              .clone()
              .weekday(viewSettingsData?.week?.start || 1);
            weekEnd = todayDate
              .clone()
              .weekday(viewSettingsData?.week?.end || 5);
            if(viewSettingsData?.week?.start > viewSettingsData?.week?.end){
              weekEnd = todayDate
              .clone()
              .weekday(viewSettingsData?.week?.end || 5).add(1, 'week');
            }
            start = weekStart.format("YYYY-MM-DD");
            end = weekEnd.format("YYYY-MM-DD");
          }
          payloadL.start_date = start;
          payloadL.end_date = end;
          payloadL.view_type = generalSetting?.planning_screen_view;
        } else {
          start = payloadL.start_date;
          end = payloadL.end_date;
          payloadL.start_date = start;
          payloadL.end_date = end;
        }
        payloadL.project_status = project_status?.filter((s: any) => parseInt(s.value) !== 0 && s.isActive === true)?.map((x: any) => parseInt(x.id));
        payloadL.project_view = true;
        setPayload(payloadL);
      }
    }
  }, [
    isfetchgeneralsetting,
    isfetchlayoutandfilters,
    viewSettingsData,
    isfetchprojectstatus
  ]);

  useEffect(() => {
    if (!isfetchlayoutandfilters && !isfetchprojectstatus) {
      let savedPreferences = loadFromLocalStorage('project_layout');
      let savedPreferencesproject = savedPreferences?.project_filter || layoutandfilters?.project_filter;
      let fromLastSaved = savedPreferences?.project_filter ? true : false;
      if (hasProjectFilter(savedPreferencesproject)) {
        
        const projectPayload = initializeProjectPayload(savedPreferencesproject);
        const { start, end } = determineStartEndDates(savedPreferencesproject, payload, viewSettingsData,fromLastSaved);
        if(fromLastSaved){
          setCurrentDate(start)
        }
  
        setProjectDates(projectPayload, start, end);
        setPayload(projectPayload);
  
        setTimeout(() => setFilterEnable(true), 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutandfilters?.data, isfetchlayoutandfilters, isfetchprojectstatus]);
  
  const hasProjectFilter = (savedPreferencesproject) => {
    return savedPreferencesproject && Object.keys(savedPreferencesproject)?.length > 0;
  };
  
  const initializeProjectPayload = (projectFilter) => {
    let projectPayload = JSON.parse(JSON.stringify(projectFilter));
    projectPayload.view_type = projectFilter.view_type || "week";
    projectPayload.project_leads = projectPayload?.project_leads || [];
    projectPayload.projects = projectPayload?.projects || [];
    projectPayload.companies = projectPayload?.companies || [];
    projectPayload.task_status = projectPayload?.task_status || [];
    projectPayload.project_start_date_month_value = projectPayload?.project_start_date_month_value || 0;
    projectPayload.project_end_date_month_value = projectPayload?.project_end_date_month_value || 0;
    projectPayload.project_start_date_option = projectPayload?.project_start_date_option || "";
    projectPayload.project_end_date_option = projectPayload?.project_end_date_option || "";
    projectPayload.project_view = projectPayload?.project_view || false;
    
    return projectPayload;
  };
  
  const determineStartEndDates = (savedPreferencesproject, payload, viewSettingsData, fromLastSaved) => {
    let todayDate = moment();
    if(fromLastSaved){
      todayDate = moment(savedPreferencesproject.start_date)
    }
    let start = payload.start_date;
    let end = payload.end_date;
    
    if (savedPreferencesproject.view_type !== "") {
      let { start: weekStart, end: weekEnd } = getWeekStartEndDates(todayDate, savedPreferencesproject, viewSettingsData);
      start = weekStart.format("YYYY-MM-DD");
      end = weekEnd.format("YYYY-MM-DD");
    }
  
    return { start, end };
  };
  
  const getWeekStartEndDates = (todayDate, savedPreferencesproject, viewSettingsData) => {
    let weekStart = todayDate.clone().startOf(savedPreferencesproject.view_type);
    let weekEnd = todayDate.clone().endOf(savedPreferencesproject.view_type);
  
    if (savedPreferencesproject.view_type === "week") {
      const { start: weekStartDay, end: weekEndDay } = viewSettingsData?.week || {};
      weekStart = todayDate.clone().endOf("week").weekday(weekStartDay);
  
      if (weekStartDay > weekEndDay) {
        weekEnd = todayDate.clone().endOf("week").weekday(weekEndDay).add(1, "week");
      } else {
        weekEnd = todayDate.clone().endOf("week").weekday(weekEndDay);
      }
    }
  
    return { start: weekStart, end: weekEnd };
  };
  
  const setProjectDates = (projectPayload, start, end) => {
    projectPayload.start_date = start;
    projectPayload.end_date = end;
  };
  
  const { _id } = currentUser?.data?.user;
  const { data: tooltipData } = useQuery(
    "tooltip-Data",
    () => {
      return getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const steps = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Projects"
  );
  
  const [enabled, setEnabled] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);

  const tooltipVisibility = {
    showTooltipVisibility: {
      Projects: false,
    },
  };
  const onExit = async () => {
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };
  
const { data: location } = useQuery(
  "location-details",
  async () => {
    return await getLocationDetails();
  },
  { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
);


  const { refetch: refetchphase, data: phaseList } = useQuery(
    "project-resources-phase",
    () => {
      return getPhase("","");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: projectTypeList, isFetching: isProjectTypeFetch } = useQuery(
    "project-type",
    () => {
      return getProjectType("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: opportunitiesList } = useQuery(
    "project-opportunities",
    () => {
      return getOpportunities("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: contractList } = useQuery(
    "project-contracts",
    () => {
      return getContracts("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: departments } = useQuery(
    "project-department-filter",
    () => {
      // debugger
      return getDepartments();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (!isfetchlayoutandfilters && !isfetchprojectstatus) {
      refetchAvailability();
      if(!isfetchprojectstatus){
        saveToLocalStorage('project_layout', {
          project_filter: payload,
        })
      }
    }
  }, [payload]);

  const [openDropdown, setOpenDropdown] = useState(null);
  const toggleDropdown = (id: any, reset = "true") => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const {
    isFetching: isRoleFetch,
    data: roles,
  } = useQuery(
    "project-roles-filter-list",
    () => {
      // debugger
      return getRoles();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isSkillFetch, data: skills } = useQuery(
    "project-skills",
    () => {
      // debugger
      return getSkills();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isTicketCategoryFetch,
    data: ticketCategory,
  } = useQuery(
    "project-TicketCategory",
    () => {
      // debugger
      return getTicketCategory();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isTicketTypeFetch,
    data: ticketType,
  } = useQuery(
    "project-TicketType",
    () => {
      // debugger
      return getTicketType();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isTaskCategoryFetch,
    data: taskCategory,
  } = useQuery(
    "project-taskCategory",
    () => {
      // debugger
      return getTaskCategory();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isSlasFetch,
    data: slas,
  } = useQuery(
    "project-Slas",
    () => {
      // debugger
      return getSlas();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isContractCategoryFetch,
    data: contractCategory,
  } = useQuery(
    "project-contractCategory",
    () => {
      // debugger
      return getContractCategory();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isQueuesFetch,
    data: queues,
  } = useQuery(
    "project-Queues",
    () => {
      // debugger
      return getQueues();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
 const { data: loggedUser } = useQuery(
  'logged-user',
  async () => {
    return await getCurrentUserDetails(_id)
  },
  {
    cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
  }
 )
 const [isReady, setIsReady] = useState<boolean>(false)

 useEffect(() => {
  if(responseAvailability?.companyData?.length > 0 && !isFetchingAvailability){
      setTimeout(() => {
        setIsReady(true)
      }, 1000)
    }
 }, [isFetchingAvailability])

  return (
    <>
       {loggedUser && loggedUser?.showTooltipVisibility?.Projects !== false && 
      responseAvailability?.companyData?.length > 0 && !isFetchingAvailability &&
      isReady &&
      (

        <>
          <Steps
            enabled={enabled}
            initialStep={initialStep}
            steps={steps?.length > 0 ? steps : []}
            onExit={onExit}
            options={{
              showStepNumbers: true,
              showButtons: true,
              hidePrev: true,
              scrollToElement: true,
              showBullets: true,
              showProgress: true,
              exitOnOverlayClick: false,
              disableInteraction: true,
            }}
          />
        </>
      )}
      <Loading
        isLoading={
          (isFetchingAvailability ||
            isfetchgeneralsetting ||
            isfetchresourcesetting ||
            isfetchlayoutandfilters || isLoaded) &&
          !isPopupOpen
        }
        isHideSpinner={true}
        className="splash-screen"
      />
      <div className={`d-flex flex-column notifyHeight`}>
        <div className="p-main h-100">
          <ProjectView
            projectData={responseAvailability}
            resource_data={responseAvailability?.companyData}
            general_settings={generalSetting}
            filterIds={payload}
            handleHeaderChange={handleHeaderView}
            filterArray={{
              peoples: peoples,
              isPeopleFetch: isPeopleFetch,
              isCompanyFetch: isCompanyFetch,
              companies: companies,
              isProjectFetch: isProjectFetch,
              projects: projects,
              task_status: task_status,
              isfetchstatus: isfetchstatus,
              project_status: project_status,
              isfetchprojectstatus: isfetchprojectstatus,
              isfetchlayoutandfilters: isfetchlayoutandfilters,
              layoutandfilters: layoutandfilters,
              islineOfBusinessFetch: islineOfBusinessFetch,
              lineOfBusiness: lineOfBusiness,
              projectTypeList: projectTypeList,
              opportunitiesList: opportunitiesList,
              departments: departments,
              contractList: contractList,
              isRoleFetch: isRoleFetch,
              roles: roles,
              isPrioritiesFetch: isPrioritiesFetch,
              priorities: priorities,
              isTicketCategoryFetch: isTicketCategoryFetch,
              ticketCategory: ticketCategory,
              isTicketTypeFetch: isTicketTypeFetch,
              ticketType: ticketType,
              taskCategory: taskCategory,
              isTaskCategoryFetch: isTaskCategoryFetch,
              isSlasFetch: isSlasFetch,
              slas: slas,
              contractCategory: contractCategory,
              isContractCategoryFetch: isContractCategoryFetch,
              queues: queues,
              isQueuesFetch: isQueuesFetch,
              taskticketstatus: task_status,
              isSkillFetch: isSkillFetch,
              skills: skills,
              projectTypes:projectTypeList,
              isProjectTypeFetch:isProjectTypeFetch
            }}
            prioritylist={priorities?.data}
            viewclassName={viewclassName}
            showviewcomponent={showviewcomponent}
            issueslist={issues?.data}
            subIssueslist={subIssues?.data}
            isFetchingAvailability={isFetchingAvailability}
            eventCalendarRef={eventCalendarRef}
            debouncedRefresh={debouncedRefresh}
            todo_status_serviceCall={todo_status_serviceCall}
            task_status={task_status}
            ticket_status={task_status}
            resource_settings={resource_settings}
            worktype={worktype}
            layoutAndFiltersData={layoutAndFiltersData}
            selectActiveLayout={selectActiveLayout}
            phaseList={phaseList}
            setIsPopupOpen={setIsPopupOpen}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            viewSettingsData={viewSettingsData[payload?.view_type]}
            refetchAvailability={refetchAvailability}
            viewBtnbackground={viewBtnbackground}
            Viewbtncolor={Viewbtncolor}
            toggleDropdown={toggleDropdown}
            openDropdown={openDropdown}
            locations={location}
            refetchLayouts={refetchLayouts}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            isSaveView={isSaveView}
            setIsSaveView={setIsSaveView}
            setOpenDropdown={setOpenDropdown}
            setisLoaded={setisLoaded}
          />
          <ViewSettings
            showviewcomponent={showviewcomponent}
            screen_view={payload?.view_type}
            isviewShown={isviewShown}
            setIsSaveView={setIsSaveView}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectPage;
