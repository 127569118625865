import React, { useState, useRef, useEffect } from 'react';
import { Datepicker } from '@mobiscroll/react';
import { KTSVG } from '../../_metronic/helpers';
import InputMask from 'react-input-mask';

// Define a type for the props
interface CustomDatePickerProps {
  id?: any;
  value?: any;
  max?: any;
  min?: any;
  onChange?: any;
  timeFormat?: any;
  dateFormat?: any;
  controls?: any;
  touchUi?: boolean;
  showOverlay?: boolean;
  display?: any;
  buttons?: any;
  defaultValue?: any;
  disabled?: boolean;
  invalid?: any;
  valid?: any;
  weekDays?: any;
  theme?: any;
  select?: any;
  calendarType?: any;
  showOuterDays?: boolean;
  maxRange?: any;
  defaultSelection?: boolean;
  showTodayButton?: boolean;
  showClearButton?: boolean;
  labels?: any;
  showLabels?: boolean;
  steps?: any;
  timeWheels?: string;
  className?: string;
  rangeHighlight?: any;
  showRangeLabels?: any;
  iconPath?: any;
  placeholder?: any;
  onClose?: any;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  id,
  value,
  max,
  min,
  onChange,
  timeFormat,
  dateFormat,
  controls,
  touchUi,
  showOverlay,
  display,
  buttons,
  defaultValue,
  disabled,
  invalid,
  valid,
  weekDays,
  theme,
  select,
  calendarType,
  showOuterDays,
  maxRange,
  defaultSelection,
  showTodayButton,
  showClearButton,
  labels,
  showLabels,
  steps,
  timeWheels,
  className,
  rangeHighlight,
  showRangeLabels,
  iconPath,
  placeholder,
  onClose,
}) => {
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>(value);
  const [uniqueClass, setUniqueClass] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const randomNum = Math.floor(Math.random() * 100000);
    setUniqueClass(`masked-input-${randomNum}`);
  }, []);

  const show = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenPicker(true);
  };

  const onCloseDatePicker = () => {
    setOpenPicker(false);
    onClose && onClose();
  };

  const handleDateChange = (event) => {
    const date = event.valueText; // Mobiscroll specific
    setStartDate(date);
    setInputValue(date);
    onChange && onChange(event);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const validateDate = (date: string): boolean => {
    const parsedDate = new Date(date);
    return (
      parsedDate.toString() !== 'Invalid Date' &&
      (!min || new Date(min) <= parsedDate) &&
      (!max || new Date(max) >= parsedDate)
    );
  };

  const handleInputBlur = (e) => {
    const date = e.target.value;
    if(date) {
      if (!validateDate(date)) {
        setError('Invalid date. Please provide a valid date.');
        setInputValue('');
        setTimeout(() => setError(null), 3000);
      } else {
        setStartDate(date);
      }
      onCloseDatePicker();
    }
  };

  return (
    <div className={`position-relative ${className}`}>
     
      <InputMask
        mask={placeholder ? placeholder.replace(/[a-zA-Z]/g, '9') : '99-99-9999'}
        value={inputValue}
        onChange={handleInputChange}
        ref={inputRef}
        onBlur={handleInputBlur}
        placeholder={placeholder ?? 'dd-mm-yyyy'}
        className={`form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark ${uniqueClass}`}
      >
        {(inputProps) => (
          <input
            {...inputProps} // Ensures onChange, onBlur, and other required props are passed unaltered
          />
        )}
      </InputMask>
      <Datepicker
        id={id}
        value={value}
        max={max}
        min={min}
        controls={controls}
        touchUi={touchUi}
        isOpen={openPicker}
        showOverlay={showOverlay}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        display={display}
        anchor={uniqueClass ? document.querySelector(`.${uniqueClass}`) : null} // Ensure uniqueClass is set before querying
        defaultValue={defaultValue}
        buttons={buttons}
        disabled={disabled}
        invalid={invalid}
        valid={valid}
        weekDays={weekDays}
        theme={theme}
        select={select}
        calendarType={calendarType}
        showOuterDays={showOuterDays}
        maxRange={maxRange}
        defaultSelection={defaultSelection}
        showTodayButton={showTodayButton}
        showClearButton={showClearButton}
        labels={labels}
        showLabels={showLabels}
        steps={steps}
        timeWheels={timeWheels}
        onClose={onCloseDatePicker}
        onChange={(e) => handleDateChange(e)}
        showOnClick={false}
        showOnFocus={false}
        inputProps={{ style: { display: 'none' } }} // Hide the default datepicker input
        rangeHighlight={rangeHighlight}
        showRangeLabels={showRangeLabels}
      />
      <button
        className={`position-absolute top-0 bottom-0 end-0 bg-transparent border-0 w-35px ${
          disabled ? 'disablecursor' : ''
        }`}
        disabled={disabled}
        onClick={(e) => show(e)}
      >
        <KTSVG
          path={iconPath ?? '/media/icons/duotune/new-icons/event-calendar.svg'}
          className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-21px d-inline-flex align-items-center mh-32px"
          svgClassName="w-13px h-auto"
        />
      </button>
      {error && (
        <div className="text-danger fw-bold fs-12">
          {error}
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
